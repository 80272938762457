import React from 'react';
import MainImg from '../images/main.png';
import '../rusComponents/maiNSection.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const MainSection = () => {
  return (
    <div className='mainSection' id='mainSection'>
      <div className='textPart'>
        <div className='f1'>
          <div className='a2'>
            <h1>На пути</h1>
            <h1>к Успеху с Экспертным Консалтингом </h1>
          </div>
          <div className='a3'>
            <h1>Наши Услуги</h1>
            <a href="#abilitiesContainer"><ArrowForwardIcon /></a>
          </div>
        </div>
      </div>
      <div className='imgPart' id='imgPart'>
        <p>От идеи к реализации. Разработка решений на будущее.</p>
        <img src={MainImg} alt="MainIMg" id='brdius' />
      </div>
    </div>
  );
};

export default MainSection;
