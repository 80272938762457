import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contactFormContainer6" id='contactFormContainer'>
      <div className="formHeader6">
        <p className="subtitle6">Contacts</p>
        <h1 className="mainTitle6">Request for Consultation</h1>
      </div>
      <div className="formContent6">
        <div className="formFields6">
          <div className="row6">
            <input type="text" className="inputField6" placeholder="Your name" />
            <input type="text" className="inputField6" placeholder="Your email address" />
          </div>
          <div className="row6">
            <input type="text" className="inputField6" placeholder="Telephone" />
            <input type="text" className="inputField6" placeholder="Subject" />
          </div>
          <textarea className="inputField6" placeholder="Your message (optional)"></textarea>
          <button className="submitButton6">Send</button>
        </div>
        <div className="contactInfo6">
          <p><strong>Telephone</strong> 094556548</p>
          <p><strong>Email address</strong> <a href="mailto:info@masshtab.am">info@masshtab.am</a></p>
          <p><strong>Address</strong> Voskerichner Street, 2/9</p>
          <p><strong>Map:</strong> <a href="https://yandex.com/maps/-/CDX9MXJa" target="_blank"> View map</a></p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
