import React from 'react';
import './Footer.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Footer = () => {
  return (
    <div className="footerContainer6" id='footerContainer'>
      <div className="footerContent6">
        <div className="footerColumn6">
          <h2>Mashtab <span className="highlight6">Consultancy</span></h2>
          <p>Мы поддерживаем наших клиентов в достижении наилучших результатов, роста компании и оптимизации рабочего процесса.</p>
        </div>
        <div className="footerColumn6">
          <h3>Компания</h3>
          <p>Услуги</p>
          <p>Текущие Проекты</p>
          <p>Контакты</p>
        </div>
        <div className="footerColumn6">
          <h3>Адрес:</h3>
          <p>Voskerichner Street, 2/9</p>
          <p>Карта</p>
          <p>Телефон 094556548</p>
          <p>Эл.адрес <a href="mailto:info@masshtab.am">info@masshtab.am</a></p>
        </div>
        <div className="footerColumn6">
          <h3>Новостная Рассылка</h3>
          <p>Получайте весь последний контент на свою электронную почту несколько раз в месяц.</p>
          <div className="subscription6">
            <div className="subscribeText6">Подписаться</div>
            <ArrowForwardIcon className="subscribeArrow6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
