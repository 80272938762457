import React from 'react';
import './Footer.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Footer = () => {
  return (
    <div className="footerContainer6" id='footerContainer'>
      <div className="footerContent6">
        <div className="footerColumn6">
          <h2>Mashtab <span className="highlight6">Consultancy</span></h2>
          <p>We support our clients in achieving the best results, company growth and workflow optimization.</p>
        </div>
        <div className="footerColumn6">
          <h3>Company</h3>
          <p>Services</p>
          <p>Current Projects</p>
          <p>Contacts</p>
        </div>
        <div className="footerColumn6">
          <h3>Address:</h3>
          <p>Voskerichner Street, 2/9</p>
          <p>Map</p>
          <p>Telephone 094556548</p>
          <p>Email <a href="mailto:info@masshtab.am">info@masshtab.am</a></p>
        </div>
        <div className="footerColumn6">
          <h3>Newsletter</h3>
          <p>Receive all the latest content in your inbox several times a month.</p>
          <div className="subscription6">
            <div className="subscribeText6">Subscribe</div>
            <ArrowForwardIcon className="subscribeArrow6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
