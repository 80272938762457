import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Arm from './components/Arm';
import Eng from './components/Eng';
import Rus from './components/Rus';
import StaffPageArm from './components/armComponents/staffPage/Staff';
import StaffPageRus from './components/rusComponents/staffPage/Staff';
import StaffPageEng from './components/engComponents/staffPage/Staff';
// import BackToTopButton from './components/BackToTopButton';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/eng" />} />
          {/* <Route path="/arm" element={<Arm />} /> */}
          <Route path="/rus" element={<Rus />} />
          <Route path="/eng" element={<Eng />} />
          <Route path="/staff/arm" element={<StaffPageArm />} />
          <Route path="/staff/rus" element={<StaffPageRus />} />
          <Route path="/staff/eng" element={<StaffPageEng />} />
        </Routes>
        {/* <BackToTopButton/> */}
      </div>
    </Router>
  );
}

export default App;
