import React from 'react';
import MainImg from '../images/main.png';
import '../engComponents/mainSection.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const MainSection = () => {
  return (
    <div className='mainSection' id='mainSection'>
      <div className='textPart'>
        <div className='f1'>
          <div className='a2'>
            <h1>On a way</h1>
            <h1>To Success with Expert Consulting</h1>
          </div>
          <div className='a35'>
            <h1>See Services</h1>
            <a href="#abilitiesContainer"><ArrowForwardIcon /></a>
          </div>
        </div>
      </div>
      <div className='imgPart' id='imgPart'>
        <p>From idea to implementation. Developing solutions for the future.</p>
        <img src={MainImg} alt="MainIMg" id='brdius'/>
      </div>
    </div>
  );
};

export default MainSection;
